import React from "react";
import ReactDOM from "react-dom/client";
import "css-resetter";
import "./index.css";
import { App } from "./App";
import { WebrtcProvider } from "y-webrtc";
import { syncedStore, getYjsDoc } from "@syncedstore/core";
import { nanoid } from "nanoid";
import _ from 'lodash';

const store = syncedStore({ games: [] });
const doc = getYjsDoc(store);

const webrtcProvider = new WebrtcProvider(
  process.env.NODE_ENV + "-clover-ricmatsui-com",
  doc,
  {
    signaling: [process.env.REACT_APP_SIGNALING_SERVER_URL],
    filterBcConns: false,
    ...(process.env.NODE_ENV !== "production"
      ? {}
      : {
          peerOpts: {
            config: {
              iceServers: (await (await fetch("./webrtc")).json()).ice_servers,
            },
          },
        }),
  }
);

const AppLoader = () => {
  const [appProps, setAppProps] = React.useState(null);
  const [loadingPhase, setLoadingPhase] = React.useState("Booting...");

  React.useEffect(() => {
    const load = async () => {
      const response = await fetch("deck.txt");
      const data = await response.text();
      const deck = data
        .split("\n")
        .filter((line) => line)
        .map((line) => line.split(" "));

      const userId =
        document.cookie
          .split("; ")
          .find((row) => row.startsWith("userId="))
          ?.split("=")[1] || nanoid();

      document.cookie = `userId=${userId};max-age=31536000`;

      setLoadingPhase("Waiting for players...");

      if (!webrtcProvider.room?.webrtcConns.size) {
        await new Promise((resolve) => {
          const handler = ({ webrtcPeers }) => {
            if (webrtcPeers.length) {
              webrtcProvider.off("peers", handler);
              resolve();
            }
          }

          webrtcProvider.on("peers", handler);
        });
      }

      setLoadingPhase("Starting sync...");

      if (!webrtcProvider.room?.synced) {
        await new Promise((resolve) => {
          const handler = () => {
            const connectionsById = webrtcProvider.room?.webrtcConns;

            if (!connectionsById.size) {
              setLoadingPhase("Reconnecting...");
              return;
            }

            if (!webrtcProvider.room?.synced) {
              const syncedCount = _(Array.from(connectionsById.values()))
                .map((peer) => peer.synced ? 1 : 0)
                .sum();
              setLoadingPhase(`Sync in progress... (${syncedCount}/${connectionsById.size})`);
              return;
            }

            webrtcProvider.off("synced", handler);
            webrtcProvider.off("peers", handler);
            resolve();
          };
          webrtcProvider.on("synced", handler);
          webrtcProvider.on("peers", handler);
        });
      }

      setAppProps({ userId, store, webrtcProvider, deck });
    };

    load();
  }, []);

  if (!appProps) {
    return <div className='text-slate-800' style={{ padding: 30 }}>{loadingPhase}</div>;
  }

  return <App {...appProps} />;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppLoader />
  </React.StrictMode>
);
