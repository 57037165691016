import "./App.css";
import React from "react";
import { useSyncedStore } from "@syncedstore/react";
import ResizableRect from "react-resizable-rotatable-draggable";
import { nanoid } from "nanoid";
import _ from "lodash";
import { getYjsValue } from "@syncedstore/core";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import cn from "clsx";
import { mdiRedo, mdiEyeLock, mdiClover, mdiCursorDefault } from "@mdi/js";
import Icon from "@mdi/react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "./tailwind.config.js";

const styleConfig = resolveConfig(tailwindConfig);

export const App = ({ userId, store, webrtcProvider, deck }) => {
  const state = useSyncedStore(store);
  const game = state.games[state.games.length - 1];
  const [selectedCardId, setSelectedCardId] = React.useState(null);
  const transformWrapperRef = React.useRef();
  const [awarenessStates, setAwarenessStates] = React.useState(new Map());
  const [userColor, setUserColor] = React.useState(getNextColor());

  React.useEffect(() => {
    const handleMove = _.throttle((event) => {
      if (event.pointerType !== "mouse") {
        return;
      }

      webrtcProvider.awareness.setLocalStateField("cursor", {
        x: event.clientX - transformWrapperRef.current.state.positionX,
        y: event.clientY - transformWrapperRef.current.state.positionY,
      });
    }, 16);

    document.addEventListener("pointermove", handleMove);

    return () => document.removeEventListener("pointermove", handleMove);
  }, [webrtcProvider]);

  React.useEffect(() => {
    const handleAwarenessUpdate = (awareness) => {
      setAwarenessStates(new Map(webrtcProvider.awareness.getStates()));
    };

    webrtcProvider.awareness.on("update", handleAwarenessUpdate);

    return () => webrtcProvider.awareness.off("update", handleAwarenessUpdate);
  }, [webrtcProvider, userId]);

  React.useEffect(() => {
    webrtcProvider.awareness.setLocalStateField("user", {
      color: userColor,
    });
  }, [userColor, webrtcProvider]);

  const handleSelectCard = React.useCallback(
    (id) => {
      webrtcProvider.awareness.setLocalStateField("selection", {
        cloverId: null,
        cardId: id,
      });
      setSelectedCloverId(null);
      setSelectedCardId(id);
    },
    [webrtcProvider]
  );

  const [selectedCloverId, setSelectedCloverId] = React.useState(null);

  const handleSelectClover = React.useCallback(
    (id) => {
      webrtcProvider.awareness.setLocalStateField("selection", {
        cloverId: id,
        cardId: null,
      });
      setSelectedCloverId(id);
      setSelectedCardId(null);
    },
    [webrtcProvider]
  );

  const handleShuffleClover = React.useCallback(
    (clover) => {
      const cloverCards = game.cards
        .filter((card) => card.active)
        .filter((card) => isIntersecting(card, clover));

      const shuffled = _.shuffle(cloverCards).map((card) =>
        getYjsValue(card).toJSON()
      );

      cloverCards.forEach((card, index) => {
        _.times(Math.floor(Math.random() * 4), () => {
          shuffled[index].words.push(shuffled[index].words.shift());
        });
        card.words = shuffled[index].words;
        card.angle = Math.floor(Math.random() * 4) * 90;
        card.flipped = true;

        if (cloverCards.length >= 5) {
          card.public = true;
        }
      });
    },
    [game]
  );

  const handleFlipClover = React.useCallback(
    (clover) => {
      const cloverCards = game.cards
        .filter((card) => card.active)
        .filter((card) => isIntersecting(card, clover));

      if (!cloverCards.length) {
        return;
      }

      const targetFlipped = !cloverCards[0].flipped;

      cloverCards.forEach((card) => (card.flipped = targetFlipped));
    },
    [game]
  );

  const handleAddCard = React.useCallback(
    (clover) => {
      const card = game.cards.find((card) => !card.active);

      if (!card) {
        alert("No cards left");
      }

      card.ownerId = userId;

      const spread = 100;

      card.x =
        clover.x +
        clover.width / 2 -
        card.width / 2 +
        Math.floor(Math.random() * spread) -
        spread / 2;

      card.y =
        clover.y +
        clover.height / 2 -
        card.height / 2 +
        Math.floor(Math.random() * spread) -
        spread / 2;

      card.active = true;
    },
    [game, userId]
  );

  const awarenessEntries = Array.from(awarenessStates.entries());

  const selectingUserColorByCloverId = _(awarenessEntries)
    .filter(
      ([clientId, state]) =>
        clientId !== webrtcProvider.awareness.clientID &&
        !!state.selection?.cloverId &&
        !!state.user?.color
    )
    .map(([_, state]) => [state.selection.cloverId, state.user.color])
    .fromPairs()
    .value();

  const selectingUserColorByCardId = _(awarenessEntries)
    .filter(
      ([clientId, state]) =>
        clientId !== webrtcProvider.awareness.clientID &&
        !!state.selection?.cardId &&
        !!state.user?.color
    )
    .map(([_, state]) => [state.selection.cardId, state.user.color])
    .fromPairs()
    .value();

  const otherUserCursors = _(awarenessEntries)
    .filter(
      ([clientId, state]) =>
        clientId !== webrtcProvider.awareness.clientID &&
        !!state.cursor &&
        !!state.user?.color
    )
    .map(([clientId, state]) => ({
      clientId,
      x: state.cursor.x,
      y: state.cursor.y,
      color: state.user.color,
    }))
    .value();

  return (
    <div className="App">
      <TransformWrapper
        ref={transformWrapperRef}
        minPositionX={0}
        minPositionY={0}
        maxPositionX={2000}
        maxPositionY={2000}
        maxScale={1}
        wheel={{ disabled: true }}
        pinch={{ disabled: true }}
        doubleClick={{ disabled: true }}
        panning={{ velocityDisabled: false, excluded: ["nopan"] }}
      >
        <TransformComponent
          wrapperStyle={{
            width: "100vw",
            height: "100vh",
            willChange: "transform",
            overscrollBehavior: "none",
          }}
        >
          <div
            className={cn(
              "rounded-xl border-8 border-solid border-neutral-400"
            )}
            style={{
              background: "url(background.svg)",
              position: "relative",
              overflow: "hidden",
              overscrollBehavior: "none",
              marginLeft: 40,
              marginRight: 40,
              marginTop: 100,
              marginBottom: 100,
              cursor: "move",
              width: 2000,
              height: 2000,
            }}
          >
            {game?.clovers.map((clover, index) => (
              <Clover
                key={index}
                clover={clover}
                selected={clover.id === selectedCloverId}
                selectingUserColor={selectingUserColorByCloverId[clover.id]}
                onSelectClover={handleSelectClover}
                onShuffleClover={handleShuffleClover}
                onFlipClover={handleFlipClover}
                onAddCard={handleAddCard}
                cards={game.cards}
              />
            ))}
            {game?.cards.map((card, index) => (
              <Card
                key={index}
                card={card}
                userId={userId}
                selected={card.id === selectedCardId}
                selectingUserColor={selectingUserColorByCardId[card.id]}
                onSelectCard={handleSelectCard}
              />
            ))}
          </div>
          {otherUserCursors.map((cursor) => (
            <Cursor key={cursor.clientId} cursor={cursor} />
          ))}
        </TransformComponent>
      </TransformWrapper>
      <Toolbar
        onSelectClover={handleSelectClover}
        {...{
          transformWrapperRef,
          game,
          state,
          deck,
          userColor,
          setUserColor,
        }}
      />
      <DebugInfo gameId={game?.id} {...{ webrtcProvider }} />
    </div>
  );
};

const Cursor = ({ cursor }) => {
  return (
    <Icon
      path={mdiCursorDefault}
      size={1}
      color={cursor.color}
      className="select-none pointer-events-none"
      style={{
        position: "absolute",
        left: cursor.x,
        top: cursor.y,
        opacity: 0.75,
        stroke: styleConfig.theme.colors.slate[800],
        strokeWidth: 2,
      }}
    />
  );
};

const Card = ({ card, selected, selectingUserColor, onSelectCard, userId }) => {
  if (!card.active) {
    return null;
  }

  const hidden = !card.public && card.ownerId !== userId;

  return (
    <>
      <div
        style={{
          position: "absolute",
          height: card.width,
          width: card.height,
          left: card.x,
          top: card.y,
          transform: `rotate(${card.angle}deg)`,
        }}
      >
        <div
          className={cn("rounded-lg")}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            ...(!selectingUserColor
              ? {}
              : {
                  outline: `3px solid ${selectingUserColor}`,
                  outlineOffset: "1px",
                }),
          }}
        />
        <div
          className={cn(
            hidden
              ? "bg-slate-400"
              : card.public
              ? "bg-slate-50"
              : "bg-slate-100",
            "rounded-lg border-2 border-solid",
            "text-slate-900",
            hidden
              ? "border-slate-300"
              : selected
              ? "border-slate-700"
              : "border-slate-400"
          )}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            mask: "url(card-mask.svg)",
            WebkitMask: "url(card-mask.svg)",
          }}
        />
        <div
          className={cn(
            "border-2 border-solid",
            hidden
              ? "border-slate-300"
              : selected
              ? "border-slate-700"
              : "border-slate-400"
          )}
          style={{
            position: "absolute",
            borderRadius: 5,
            left: "50%",
            top: "50%",
            width: 42,
            height: 42,
            transform: "translate(-50%, -50%)",
          }}
        />
        {card.flipped || hidden ? null : <WordSquare words={card.words} />}
        <div
          className="nopan"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -50%) rotate(${-card.angle}deg)`,
          }}
        >
          {card.public ? null : (
            <Icon
              path={mdiEyeLock}
              size={0.7}
              color={styleConfig.theme.colors.slate[hidden ? 700 : 900]}
              className="nopan"
            />
          )}
        </div>
      </div>
      <ResizableRect
        left={card.x}
        top={card.y}
        width={card.width}
        height={card.height}
        rotateElement={
          <div
            className="nopan"
            style={{
              padding: 5,
              transform: "translate(5px, -5px)",
              opacity: 0.8,
            }}
          >
            <Icon
              className="nopan"
              path={mdiRedo}
              rotate={45}
              size={0.8}
              color={styleConfig.theme.colors.slate[800]}
            />
          </div>
        }
        rotatable={true}
        rotateAngle={card.angle}
        onRotate={(angle) => {
          card.angle = angle;
        }}
        onDragStart={() => {
          onSelectCard(card.id);
        }}
        onDrag={(deltaX, deltaY) => {
          card.x += deltaX;
          card.y += deltaY;
        }}
      ></ResizableRect>
    </>
  );
};

const Clover = ({
  clover,
  selected,
  selectingUserColor,
  onSelectClover,
  onShuffleClover,
  onAddCard,
  onFlipClover,
  cards,
}) => {
  const [intersectingCardValues, setIntersectingCardValues] = React.useState(
    []
  );
  const [startAngle, setStartAngle] = React.useState(clover.angle);

  const saveIntersectingDetails = () => {
    setIntersectingCardValues(
      cards
        .filter((card) => card.active)
        .filter((card) => isIntersecting(card, clover))
        .map((card) => getYjsValue(card).toJSON())
    );
  };

  return (
    <>
      <div
        className={cn(
          "bg-lime-500",
          "text-neutral-900",
          "rounded-lg border-4 border-solid",
          "overflow-hidden",
          selected ? "border-lime-900 border-4" : "border-lime-600"
        )}
        style={{
          position: "absolute",
          height: clover.width,
          width: clover.height,
          left: clover.x,
          top: clover.y,
          transform: `rotate(${clover.angle}deg)`,
          ...(!selectingUserColor
            ? {}
            : {
                outline: `3px solid ${selectingUserColor}`,
                outlineOffset: "1px",
              }),
        }}
      >
        <div style={{ opacity: 0.5 }}>
          <Icon
            path={mdiClover}
            size={clover.width + 62 + "px"}
            color={styleConfig.theme.colors.lime[600]}
            style={{ position: "absolute", left: -35, top: -35 }}
            className="nopan"
          />
          <div
            className={cn("bg-lime-600")}
            style={{
              position: "absolute",
              width: 100,
              height: 100,
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
        <div
          className={cn("bg-lime-600", "rounded-lg")}
          style={{
            position: "absolute",
            width: 37,
            height: 37,
            left: "35%",
            top: "35%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <div
          className={cn("bg-lime-600", "rounded-lg")}
          style={{
            position: "absolute",
            width: 37,
            height: 37,
            left: "65%",
            top: "35%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <div
          className={cn("bg-lime-600", "rounded-lg")}
          style={{
            position: "absolute",
            width: 37,
            height: 37,
            left: "35%",
            top: "65%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <div
          className={cn("bg-lime-600", "rounded-lg")}
          style={{
            position: "absolute",
            width: 37,
            height: 37,
            left: "65%",
            top: "65%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
      {!selected ? null : (
        <div
          className={cn()}
          style={{
            position: "absolute",
            left: clover.x,
            top: clover.y - 50,
            width: clover.width,
            height: clover.height + 100,
            transform: `rotate(${
              clover.angle - Math.floor((clover.angle + 45) / 90) * 90
            }deg)`,
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
          >
            <Button onClick={() => onAddCard(clover)}>Add Card</Button>
            <Button onClick={() => onFlipClover(clover)}>Flip</Button>
            <Button onClick={() => onShuffleClover(clover)}>Shuffle</Button>
          </div>
        </div>
      )}
      <ResizableRect
        left={clover.x}
        top={clover.y}
        width={clover.width}
        height={clover.height}
        rotateElement={
          <div
            className="nopan"
            style={{
              padding: 20,
              transform: "translate(5px, -5px)",
              opacity: 0.8,
            }}
          >
            <Icon
              className="nopan"
              path={mdiRedo}
              rotate={45}
              size={0.8}
              color={styleConfig.theme.colors.slate[800]}
            />
          </div>
        }
        rotatable={true}
        rotateAngle={clover.angle}
        onRotateStart={() => {
          setStartAngle(clover.angle);
          saveIntersectingDetails();
        }}
        onRotate={(angle) => {
          const diff = angle - startAngle;
          //console.log({ diff });
          clover.angle = angle;

          const intersectingCardValuesById = _.keyBy(
            intersectingCardValues,
            "id"
          );

          cards
            .filter((card) => intersectingCardValuesById[card.id])
            .forEach((card) => {
              const values = intersectingCardValuesById[card.id];

              const angleRad = degToRad(diff);

              const rotatedX =
                Math.cos(angleRad) *
                  (values.x + 50 - clover.x - clover.width / 2) -
                Math.sin(angleRad) *
                  (values.y + 50 - clover.y - clover.height / 2) +
                clover.x +
                clover.width / 2;
              const rotatedY =
                Math.sin(angleRad) *
                  (values.x + 50 - clover.x - clover.width / 2) +
                Math.cos(angleRad) *
                  (values.y + 50 - clover.y - clover.height / 2) +
                clover.y +
                clover.height / 2;

              card.x = rotatedX - 50;
              card.y = rotatedY - 50;
              card.angle = values.angle + diff;
            });
        }}
        onDragStart={(e) => {
          onSelectClover(clover.id);
          saveIntersectingDetails();
        }}
        onDrag={(deltaX, deltaY) => {
          clover.x += deltaX;
          clover.y += deltaY;

          const intersectingCardValuesById = _.keyBy(
            intersectingCardValues,
            "id"
          );

          cards
            .filter((card) => intersectingCardValuesById[card.id])
            .forEach((card) => {
              card.x += deltaX;
              card.y += deltaY;
            });
        }}
      ></ResizableRect>
      <div
        className={cn()}
        style={{
          pointerEvents: "none",
          position: "absolute",
          left: clover.x,
          top: clover.y,
          width: clover.width,
          height: clover.height,
          transform: `rotate(${clover.angle}deg)`,
        }}
      >
        <CloverWords clover={clover} words={clover.words} />
      </div>
    </>
  );
};

const CloverWords = ({ clover, words }) => (
  <>
    <CloverWord
      angle={0}
      style={{ top: 70 }}
      cloverWidth={clover.width}
      cloverHeight={clover.height}
      word={words[0]}
      onClick={() =>
        changeCloverWord({
          clover,
          word: prompt("Enter word"),
          index: 0,
        })
      }
    />
    <CloverWord
      angle={90}
      style={{ left: -70 }}
      cloverWidth={clover.width}
      cloverHeight={clover.height}
      word={words[1]}
      onClick={() =>
        changeCloverWord({
          clover,
          word: prompt("Enter word"),
          index: 1,
        })
      }
    />
    <CloverWord
      angle={180}
      style={{ top: -70 }}
      cloverWidth={clover.width}
      cloverHeight={clover.height}
      word={words[2]}
      onClick={() =>
        changeCloverWord({
          clover,
          word: prompt("Enter word"),
          index: 2,
        })
      }
    />
    <CloverWord
      angle={270}
      style={{ left: 70 }}
      cloverWidth={clover.width}
      cloverHeight={clover.height}
      word={words[3]}
      onClick={() =>
        changeCloverWord({
          clover,
          word: prompt("Enter word"),
          index: 3,
        })
      }
    />
  </>
);

const CloverWord = ({
  cloverWidth,
  cloverHeight,
  onClick,
  word,
  angle,
  style,
}) => (
  <button
    onClick={onClick}
    className={cn(
      "border-2 border-slate-500 border-solid",
      "bg-white rounded-md",
      "flex items-center justify-center",
      "pl-4 pr-4",
      "nopan"
    )}
    style={{
      pointerEvents: "auto",
      position: "absolute",
      ...style,
      height: 44,
      minWidth: 150,
      transform: `translate(${cloverWidth / 2}px, ${
        cloverHeight / 2
      }px) translate(-50%, -50%) rotate(${angle}deg) translate(0px, -${
        cloverHeight / 2
      }px) translate(0, -50%)`,
    }}
  >
    {word}
  </button>
);

const WordSquare = ({ words }) => (
  <>
    <div
      style={{
        position: "absolute",
        left: 0,
        height: 0,
        width: "100%",
        ...(words[0].length < 8 ? {} : { fontSize: "12px" }),
      }}
    >
      {words[0]}
    </div>
    <div
      style={{
        position: "absolute",
        transform: "translate(-50%, 0) rotate(90deg) translate(50%, 0)",
        left: "100%",
        height: 0,
        width: "100%",
        ...(words[1].length < 8 ? {} : { fontSize: "12px" }),
      }}
    >
      {words[1]}
    </div>
    <div
      style={{
        position: "absolute",
        transform: "translate(-50%, 0) rotate(180deg) translate(50%, 0)",
        left: "100%",
        top: "100%",
        height: 0,
        width: "100%",
        ...(words[2].length < 8 ? {} : { fontSize: "12px" }),
      }}
    >
      {words[2]}
    </div>
    <div
      style={{
        position: "absolute",
        transform: "translate(-50%, 0) rotate(270deg) translate(50%, 0)",
        left: 0,
        top: "100%",
        height: 0,
        width: "100%",
        ...(words[3].length < 8 ? {} : { fontSize: "12px" }),
      }}
    >
      {words[3]}
    </div>
  </>
);

const isIntersecting = (a, b) => {
  const widthIsPositive =
    Math.min(a.x + a.width, b.x + b.width) > Math.max(a.x, b.x);
  const heightIsPositive =
    Math.min(a.y + a.height, b.y + b.height) > Math.max(a.y, b.y);
  return widthIsPositive && heightIsPositive;
};

const changeCloverWord = ({ clover, word, index }) => {
  const words = clover.words.toJSON();
  words[index] = word;
  clover.words = words;
};

const Toolbar = ({
  onSelectClover,
  transformWrapperRef,
  game,
  state,
  deck,
  userColor,
  setUserColor,
}) => {
  return (
    <div
      className={cn(
        "p-2 absolute inset-x-0 top-0",
        "bg-slate-50/70 border-b-2 border-slate-300 border-solid"
      )}
    >
      <div className="flex flex-row flex-wrap">
        <Button
          onClick={async () => {
            if (!window.confirm("Warning: Are you sure you want to reset the game for everyone?")) {
              return;
            }

            state.games.splice(0, state.games.length);
            state.games.push({
              id: nanoid(),
              clovers: [],
              cards: _.shuffle(deck).map((words) => ({
                words,
                x: 0,
                y: 0,
                width: 100,
                height: 100,
                angle: Math.floor(Math.random() * 4) * 90,
                active: false,
                flipped: true,
                public: false,
                id: nanoid(),
              })),
            });
          }}
        >
          Reset Game
        </Button>
        {!game ? null : (
          <>
            <Button
              onClick={() => {
                const id = nanoid();

                //transformWrapperRef.current.setTransform(-400, -400);

                game.clovers.push({
                  words: ["", "", "", ""],
                  x:
                    -transformWrapperRef.current.state.positionX -
                    40 -
                    180 +
                    document.documentElement.clientWidth / 2 +
                    Math.floor(Math.random() * 50) -
                    25,
                  y:
                    -transformWrapperRef.current.state.positionY -
                    100 -
                    180 +
                    document.documentElement.clientHeight / 2 +
                    Math.floor(Math.random() * 50) -
                    25,
                  angle: 0,
                  width: 360,
                  height: 360,
                  id,
                });

                onSelectClover(id);
              }}
            >
              Add Clover
            </Button>
            <button
              className={cn(
                "border-2 border-slate-500",
                "bg-slate-200 rounded-md",
                "hover:bg-slate-300",
                "active:bg-slate-400",
                "text-slate-700 select-none",
                "pt-2 pb-2 pl-4 pr-4 mr-2 mt-2"
              )}
              onClick={() => {
                setUserColor(getNextColor());
              }}
              style={{
                background: userColor,
                width: 44,
                height: 44,
              }}
            ></button>
          </>
        )}
      </div>
    </div>
  );
};

const DebugInfo = React.memo(({ webrtcProvider, gameId }) => {
  const [synced, setSynced] = React.useState(
    webrtcProvider.room?.synced || false
  );

  React.useEffect(() => {
    const listener = ({ synced }) => {
      setSynced(synced);
    };
    webrtcProvider.on("synced", listener);

    return () => {
      webrtcProvider.off("synced", listener);
    };
  }, [webrtcProvider]);

  const [peerIds, setPeerIds] = React.useState(
    Array.from(webrtcProvider.room?.webrtcConns.keys())
  );

  React.useEffect(() => {
    const listener = ({ webrtcPeers }) => {
      setPeerIds(webrtcPeers);
    };
    webrtcProvider.on("peers", listener);

    return () => {
      webrtcProvider.off("peers", listener);
    };
  }, [webrtcProvider]);

  React.useEffect(() => {
    const interval = setInterval(async () => {
      if (process.env.NODE_ENV === "production") {
        webrtcProvider.peerOpts = {
          config: {
            iceServers: (await (await fetch("./webrtc")).json()).ice_servers,
          },
        };
      }

      webrtcProvider.disconnect();
      webrtcProvider.connect();
    }, 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, [webrtcProvider]);

  return (
    <div
      className={cn(
        "p-2 absolute inset-x-0 bottom-0",
        "bg-slate-50/70 border-t-2 border-slate-300 border-solid"
      )}
    >
      <div className="flex flex-row flex-wrap text-slate-600 text-left">
        Game: {gameId?.substring(0, 5) || "N/A"} | Peers: {peerIds.length}
        {synced ? " | Synced" : ""}
      </div>
      <div className="flex flex-row flex-wrap text-slate-600 text-left text-nowrap overflow-hidden">
        ID: {webrtcProvider.room.peerId.slice(0, 5)} | Others:{" "}
        {!peerIds.length
          ? "N/A"
          : peerIds.map((id) => id.slice(0, 5)).join(", ")}
      </div>
    </div>
  );
});

const Button = (props) => (
  <button
    className={cn(
      "border-2 border-slate-500",
      "bg-slate-200 rounded-md",
      "hover:bg-slate-300",
      "active:bg-slate-400",
      "text-slate-700 select-none",
      "pt-2 pb-2 pl-4 pr-4 mr-2 mt-2"
    )}
    {...props}
  />
);

const degToRad = (degrees) => {
  return degrees * (Math.PI / 180);
};

const getWordIndexAfterRotation = (index, angle) => {
  if (angle < 45) {
    return index;
  }

  if (angle < 45 + 90) {
    return (index + 3) % 4;
  }

  if (angle < 45 + 2 * 90) {
    return (index + 2) % 4;
  }

  return (index + 1) % 4;
};

let hue = Math.floor(Math.random() * 360);

const getNextColor = () => {
  while (true) {
    hue = (hue + 3) % 360;
    const [r, g, b] = hslToRgb(hue, 100, 50);
    const colorLuminance = rgbToLuminance(r, g, b);
    let contrastRatio = calculateContrastRatio(colorLuminance, 1);

    if (contrastRatio > 1.5) {
      return `hsl(${hue}deg, 100%, 50%)`;
    }
  }
};

function hslToRgb(h, s, l) {
  s /= 100;
  l /= 100;
  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  let m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b];
}

function rgbToLuminance(r, g, b) {
  let a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function calculateContrastRatio(l1, l2) {
  return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
}
